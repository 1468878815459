<template>
  <div class="patrol">
     <el-button class="btn" type="primary" icon="el-icon-arrow-left" @click="goback()">返回</el-button>
    <div class="patrol-center">
      <div class="patrol-center-map">
        <TitleBox :titlename="'无人机巡护轨迹'" />
        <img src="../../assets/homeimg/xh.png" alt="">
      </div>
      <div class="patrol-center-img">
        <TitleBox :titlename="'无人机巡护视频'" />
        <img src="../../assets/homeimg/1223.png" alt="">
      </div>
      <div class="patrol-center-list">
        <TitleBox :titlename="'无人机巡护列表'" />
        <div class="tabbox">
          <ul>
            <li v-for="(item,index) in tableData" :key="index">
              <div class="circle"></div>
              <img src="../../assets/homeimg/icon1_1.png" alt="">
              <p>{{item.name}}</p>
              <p>{{item.date}}</p>
              <p>{{item.address}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: {},
  components: {
    TitleBox
  },
  data () {
    return {
      tableData: [{
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }, {
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }, {
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }, {
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }, {
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }, {
        date: '2022-07-15',
        name: '12:00',
        address: '巡航'
      }]
    }
  },
  computed: {},
  watch: {},
  methods: {
    goback () {
      this.$router.go(-1)
    },
    footerbtn (index) {
      this.footerAction = index
    }
  },
  created () { }
}
</script>
<style lang="less" scoped>
ul li {
  margin: 0;
  padding: 0;
}
.patrol {
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .btn{
    position: absolute;
    top: 10%;
    left: 5%;
    z-index: 2;
  }
  &-center {
    width: 90%;
    height: 75%;
    margin: 8% 0 1% 5%;
    background: url('../../assets/homeimg/box.png') no-repeat;
    background-size: 100% 100%;
    // margin-left: 5%;
    overflow: hidden;
    display: flex;
    &-map {
      width: 40%;
      height: 90%;
      margin: 2% 0 0 1%;
      img {
        width: 100%;
        height: 90%;
        margin-top: 2%;
      }
    }
    &-img {
      width: 40%;
      height: 90%;
      margin: 2% 0 0 1%;
      img {
        width: 100%;
        height: 90%;
        margin-top: 2%;
      }
    }
    &-list {
      width: 15%;
      height: 90%;
      margin: 2% 0 0 2%;
      .tabbox {
        width: 100%;
        height: 73%;
        margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .circle {
              width: 6px;
              height: 6px;
              background: #ffffff;
              border-radius: 50%;
            }
            img {
              width: 26px;
              height: 19px;
            }
            p {
              line-height: 30px;
              color: #fff;
              margin: 0;
            }
          }
          // li:nth-child(2n) {
          //   background: RGBA(56, 74, 97, 0.2);
          // }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
